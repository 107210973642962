import { Icon } from '@bukuwarung/sachet'

interface InvoiceModalProps {
    /**
     * Show or hide the drawer
     */
    show?: boolean
    /**
     * Drawer content
     */
    children?: JSX.Element | JSX.Element[]
    /**
     * Drawer title text
     */
    title?: string | JSX.Element
    /**
     * Callback function when drawer is closed
     * @returns {void} - Callback function when drawer is closed
     */
    onDrawerClose?: () => void
}

export const AnalyticsDrawer = ({ show = false, children, title, onDrawerClose = () => false }: InvoiceModalProps) => {
    if (!show) {
        return <></>
    }

    return (
        <div
            data-testid="analytics-drawer"
            className="absolute bottom-0 right-0 z-10 flex h-full w-[600px] max-w-full flex-col overflow-hidden bg-neutrals-light drop-shadow-md"
            tabIndex={-1}
            aria-labelledby="drawer-label">
            <div
                id="drawer-label"
                className="flex h-[60px] flex-row items-center justify-between border-b border-neutrals-200 px-4 py-4 sm:px-5">
                <div className="flex-1 font-semibold">{title}</div>
                <div className="ml-5 cursor-pointer" data-testid="analytics-drawer-close-button" onClick={() => onDrawerClose()}>
                    <Icon name="x-outline" size="xl"></Icon>
                </div>
            </div>
            <div className="flex flex-grow overflow-hidden">{children}</div>
        </div>
    )
}
