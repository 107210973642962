import { Icon, LineSkeleton, Pagination, Table, TableBody, TableColumn, TableRow, Typography } from '@bukuwarung/sachet'
import { formatNumberToCurrencyString } from '@bukuwarung/sachet/utils'
import AnimatedNumber from 'animated-number-react'
import { useEffect, useState } from 'react'
import { useGlobal } from '~/components/Global/GlobalProvider'
import { AnalyticsCustomerI, AnalyticsCustomerParamsI, GetAnalyticsCustomer } from '~/data/accounting'
import { delayedCallback } from '~/utils/common'

interface AnalyticsCustomerProps {
    type?: 'summary' | 'detail'
    pageSize?: number
    startDateTime: string
    endDateTime: string
    title?: boolean
    className?: string
    onClickMore?: () => void
}

export const AnalyticsCustomer = ({
    type = 'summary',
    pageSize = 10,
    startDateTime,
    endDateTime,
    title = true,
    className = '',
    onClickMore = () => false
}: AnalyticsCustomerProps) => {
    const { snackbar } = useGlobal()
    const [loading, setLoading] = useState(true)
    const [filter, setFilter] = useState<AnalyticsCustomerParamsI>(() => ({
        pageNo: 0,
        pageSize,
        startDateTime,
        endDateTime
    }))
    const [data, setData] = useState<AnalyticsCustomerI>({
        customerList: [],
        pagination: {
            pageNo: 0,
            pageSize,
            totalElements: 0,
            totalPages: 0
        }
    })

    useEffect(() => {
        load(0)
    }, [startDateTime, endDateTime])

    const load = (pageNo: number) => {
        setLoading(true)

        const _filter = {
            ...filter,
            pageNo,
            startDateTime,
            endDateTime
        }

        setFilter(_filter)

        GetAnalyticsCustomer({
            params: { ..._filter, startDateTime, endDateTime },
            onSuccess: res => {
                setData(res)
            },
            onError: message => {
                snackbar.snackbarHandle({
                    message,
                    value: true,
                    style: 'error'
                })
            }
        }).then(() => {
            delayedCallback(() => {
                setLoading(false)
            }, 500)
        })
    }

    return (
        <div className={`flex flex-col ${className}`}>
            <div className="flex-grow overflow-auto">
                {title && (
                    <>
                        <Typography variant="h3" fontWeight="medium" size="lg" className="mb-3 flex items-center">
                            <span className="mr-1">Pembeli</span>
                        </Typography>
                        <>
                            {loading ? (
                                <LineSkeleton count={1} size="3xl" className="w-32" />
                            ) : (
                                <Typography size="2xl" fontWeight="bold">
                                    <AnimatedNumber
                                        duration={200}
                                        value={data.pagination.totalElements}
                                        formatValue={(value: number) => formatNumberToCurrencyString(Math.round(value))}></AnimatedNumber>
                                </Typography>
                            )}
                        </>
                    </>
                )}
                <Table>
                    <TableBody>{renderContent(loading, type, data)}</TableBody>
                </Table>
            </div>
            {type === 'detail' ? (
                data.pagination.totalElements > 0 && (
                    <div className="flex items-center justify-center border-t border-neutrals-200 px-4 pb-4 pt-4 sm:justify-between sm:px-5 sm:pt-5">
                        <span className="mr-1 hidden text-sm sm:block">
                            {data.customerList.length + data.pagination.pageSize * data.pagination.pageNo} dari{' '}
                            {data.pagination.totalElements} pembeli
                        </span>
                        <Pagination
                            total={data.pagination.totalElements}
                            itemPerPage={filter.pageSize}
                            current={filter.pageNo + 1}
                            onSelect={value => {
                                load(value - 1)
                            }}></Pagination>
                    </div>
                )
            ) : (
                <>
                    {data.pagination.totalElements > pageSize && !loading && (
                        <a
                            role="button"
                            className="mt-4 flex items-center justify-end text-center text-sm text-primary no-underline hover:underline sm:mt-5"
                            onClick={onClickMore}>
                            <span className="mr-1">Lihat lebih banyak</span>
                            <Icon name="chevron-right"></Icon>
                        </a>
                    )}
                </>
            )}
        </div>
    )
}

export const renderContent = (loading: boolean, type: 'detail' | 'summary', data: AnalyticsCustomerI) => {
    if (loading) {
        return Array(3)
            .fill(1)
            .map((_, key) => (
                <TableRow key={key}>
                    <TableColumn paddingLeft={type === 'detail' ? 'lg' : 'none'}>
                        <LineSkeleton count={1} size="lg" className="w-32" />
                    </TableColumn>
                    <TableColumn paddingRight={type === 'detail' ? 'lg' : 'none'}>
                        <LineSkeleton count={1} size="lg" className="w-32" />
                    </TableColumn>
                </TableRow>
            ))
    }

    if (data.customerList.length === 0) {
        return (
            <TableRow>
                <TableColumn paddingLeft="none">
                    <Typography>Tidak ada data.</Typography>
                </TableColumn>
            </TableRow>
        )
    }

    return data.customerList.map((item, index) => (
        <TableRow key={item.id} border={!(index === data.customerList.length - 1 && type === 'detail')}>
            <TableColumn paddingLeft={type === 'detail' ? 'lg' : 'none'}>
                <span className="font-normal">{item.name}</span>
            </TableColumn>
            <TableColumn paddingRight={type === 'detail' ? 'lg' : 'none'}>
                <p className="text-right font-normal">Rp{formatNumberToCurrencyString(Math.round(item.totalCost))}</p>
            </TableColumn>
        </TableRow>
    ))
}
