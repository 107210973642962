import { INVOICE_API_BASE_URL } from '~/constants'
import $axios from '~/lib/axios'
import locale from '~/locale'
import { InvoiceExpenseTypeT, InvoicePaymentMethodT } from '~/types/invoice'
import { getErrorMessage } from '~/utils/api'

export type ApiResponse<T> = {
    result: boolean
    data: T
}

export type ApiRequest<Params, Data> = (options: {
    params?: Params
    onSuccess: (data: Data) => void
    onError: (message: string) => void
}) => Promise<void>

export interface AnalyticsSummaryParamsI {
    startDateTime: string
    endDateTime: string
    withSalesData: boolean
    withMoneyReceivedData: boolean
    withDulyPaidData: boolean
    withPaymentMethodData: boolean
    invoicePayable: boolean
}

export interface AnalyticsSummaryI {
    // when withSalesData is true
    totalSales?: number
    totalPaidSales?: number
    totalUnpaidSales?: number
    // when withMoneyReceivedData is true
    totalMoneyReceived?: number
    //when withDulyPaidData is true
    totalOnTimePayment?: number
    totalLatePayment?: number
    // when withPaymentMethodData is true
    paymentMethodCount?: AnalyticsInvoicePaymentMethodT
    // when withDulyPaidData or withPaymentMethodData is true
    totalPaidInvoices?: number
    totalPaymentsRecorded?: number
    // when invoicePayable is true
    invoicePayableCategoryCount?: { [key in InvoiceExpenseTypeT]: number }
}

export type AnalyticsInvoicePaymentMethodT = {
    [key in InvoicePaymentMethodT]: number
}

export const GetAnalyticsSummary: ApiRequest<AnalyticsSummaryParamsI, AnalyticsSummaryI> = async ({ params, onError, onSuccess }) => {
    return $axios
        .get<ApiResponse<AnalyticsSummaryI>>(`${INVOICE_API_BASE_URL}/analytics/summary`, {
            params
        })
        .then(res => {
            if (res.status === 200 && res.data.result) {
                onSuccess(res.data.data)

                return
            }

            onError(locale.id.common.request_error)
        })
        .catch(err => {
            onError(getErrorMessage(false, err).error.message)
        })
}

export interface AnalyticsProductParamsI {
    startDateTime: string
    endDateTime: string
    pageNo: number
    pageSize: number
}

export interface AnalyticsProductI {
    productList: {
        id: string
        name: string
        soldCount: number
    }[]
    pagination: {
        pageNo: number
        pageSize: number
        totalElements: number
        totalPages: number
    }
}

export const GetAnalyticsProduct: ApiRequest<AnalyticsProductParamsI, AnalyticsProductI> = async ({ params, onError, onSuccess }) => {
    return $axios
        .get<ApiResponse<AnalyticsProductI>>(`${INVOICE_API_BASE_URL}/analytics/product`, {
            params
        })
        .then(res => {
            if (res.status === 200 && res.data.result) {
                onSuccess(res.data.data)

                return
            }

            onError(locale.id.common.request_error)
        })
        .catch(err => {
            onError(getErrorMessage(false, err).error.message)
        })
}

export interface AnalyticsCustomerParamsI {
    startDateTime: string
    endDateTime: string
    pageNo: number
    pageSize: number
}

export interface AnalyticsCustomerI {
    customerList: {
        id: string
        name: string
        totalCost: number
    }[]
    pagination: {
        pageNo: number
        pageSize: number
        totalElements: number
        totalPages: number
    }
}

export const GetAnalyticsCustomer: ApiRequest<AnalyticsCustomerParamsI, AnalyticsCustomerI> = async ({ params, onError, onSuccess }) => {
    return $axios
        .get<ApiResponse<AnalyticsCustomerI>>(`${INVOICE_API_BASE_URL}/analytics/customer`, {
            params
        })
        .then(res => {
            if (res.status === 200 && res.data.result) {
                onSuccess(res.data.data)

                return
            }

            onError(locale.id.common.request_error)
        })
        .catch(err => {
            onError(getErrorMessage(false, err).error.message)
        })
}

export interface AnalyticsVendorParamsI {
    startDateTime: string
    endDateTime: string
    pageNo: number
    pageSize: number
}

export interface AnalyticsVendorI {
    vendorList: {
        id: string
        name: string
        totalCost: number
    }[]
    pagination: {
        pageNo: number
        pageSize: number
        totalElements: number
        totalPages: number
    }
}

export const GetAnalyticsVendor: ApiRequest<AnalyticsVendorParamsI, AnalyticsVendorI> = async ({ params, onError, onSuccess }) => {
    return $axios
        .get<ApiResponse<AnalyticsVendorI>>(`${INVOICE_API_BASE_URL}/analytics/vendor`, {
            params
        })
        .then(res => {
            if (res.status === 200 && res.data.result) {
                onSuccess(res.data.data)

                return
            }

            onError(locale.id.common.request_error)
        })
        .catch(err => {
            onError(getErrorMessage(false, err).error.message)
        })
}

export interface AnalyticsGraphI {
    timeFrame: 'DAY' | 'WEEK' | 'MONTH'
    revenueReceived: {
        startDate: string
        endDate: string
        totalRevenue: number
    }[]
}

export interface AnalyticsSalesGraphParamsI {
    startDate: string
    endDate: string
    timeFrame: AnalyticsGraphI['timeFrame']
    invoicePayable?: boolean
}
export const GetAnalyticsSalesGraph: ApiRequest<AnalyticsSalesGraphParamsI, AnalyticsGraphI> = async ({ params, onError, onSuccess }) => {
    return $axios
        .get<ApiResponse<AnalyticsGraphI>>(`${INVOICE_API_BASE_URL}/analytics/graph/sales`, {
            params
        })
        .then(res => {
            if (res.status === 200 && res.data.result) {
                onSuccess(res.data.data)

                return
            }

            onError(locale.id.common.request_error)
        })
        .catch(err => {
            onError(getErrorMessage(false, err).error.message)
        })
}

export interface AnalyticsPaymentsGraphParamsI {
    startDateTime: string
    endDateTime: string
    timeFrame: AnalyticsGraphI['timeFrame']
    invoicePayable?: boolean
}

export const GetAnalyticsPaymentsGraph: ApiRequest<AnalyticsPaymentsGraphParamsI, AnalyticsGraphI> = async ({
    params,
    onError,
    onSuccess
}) => {
    return $axios
        .get<ApiResponse<AnalyticsGraphI>>(`${INVOICE_API_BASE_URL}/analytics/graph/payments`, {
            params
        })
        .then(res => {
            if (res.status === 200 && res.data.result) {
                onSuccess(res.data.data)

                return
            }

            onError(locale.id.common.request_error)
        })
        .catch(err => {
            onError(getErrorMessage(false, err).error.message)
        })
}
