import { AccountingDashboard } from '~/components/Analytics/AccountingDashboard'
import { useGlobal } from '~/components/Global/GlobalProvider'

const Homepage = () => {
    const { store } = useGlobal()

    if (!store) {
        return <></>
    }

    return <AccountingDashboard></AccountingDashboard>
}

export default Homepage
