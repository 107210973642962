import { APIDataResponse, DataResponse, PaginationResponse, VendorBankDetails } from '..'
import { ProductWholesale } from '../../types/product/index'

export type InvoiceStatus = 'PAID' | 'UNPAID' | 'DRAFT' | 'CANCELLED' | 'PARTIALLY_PAID'
export type InvoicePaymentMethodT = 'CASH' | 'BANK TRANSFER' | 'E-WALLET' | 'QRIS' | 'EDC' | 'CARD'
type InvoiceDiscountTypeT = 'FLAT' | 'PERCENTAGE'
export type InvoicePaymentTypeT = 'MANUAL' | 'DIGITAL'
export type InvoicePayableCategoryT = 'PURCHASE' | 'EXPENSE'
export type InvoiceExpenseTypeT =
    | 'PURCHASE'
    | 'GAJI ATAU UPAH'
    | 'TRANSPORTASI'
    | 'LISTRIK'
    | 'AIR ATAU PDAM'
    | 'PULSA ATAU PAKET DATA'
    | 'INTERNET'
    | 'SEWA'
    | 'ALAT TULIS KANTOR'
    | 'LAINNYA'

export interface InvoiceVendorI<TVendorBankDetails = VendorBankDetails[]> {
    vendorId?: string
    vendorName?: string
    vendorPhoneNumber?: string
    vendorAddress?: string
    vendorBankDetails?: TVendorBankDetails
}

export interface InvoiceCustomerI {
    customerId?: string
    customerName?: string
    customerPhoneNumber?: string
    customerAddress?: string
}

export interface InvoiceDateI {
    billingDate?: string
    dueDate?: string
}

interface InvoicePaymentI {
    paymentDateTime?: string
    paymentType?: InvoicePaymentTypeT
    paymentMethod?: InvoicePaymentMethodT
}

export interface InvoicePaymentInfoI {
    id: string
    amount: number
    type: InvoicePaymentTypeT
    method: InvoicePaymentMethodT
    receivedAt: string
}

export interface InvoiceFilterParams extends InvoiceApiParams {
    query: string
}

export enum InvoiceModes {
    create = 'CREATE',
    edit = 'EDIT'
}

export interface CreateInvoiceParams extends InvoiceDateI, InvoiceCustomerI, InvoiceVendorI<VendorBankDetails | undefined> {
    referenceNumber?: string
    storeId: string
    status: InvoiceStatus
    paymentInfo?: { amount?: number } & InvoicePaymentI
    products: InvoiceProduct[]
    productTotalCost: number
    shippingCost?: number
    discount?: number
    discountType?: InvoiceDiscountTypeT
    totalCost: number
    billingNote?: string
    notifyCustomer?: boolean
    invoicePayable?: boolean
    invoicePayableCategory?: InvoicePayableCategoryT
    expenseType?: InvoiceExpenseTypeT
    externalInvoiceNumber?: string // only for AP invoice
    invoiceDocumentUrl?: string[]
}

export interface InvoiceApiResponse {
    invoiceList: GetInvoiceApiResponse[]
    pagination: PaginationResponse
}

export interface GetInvoiceApiResponse extends InvoiceDateI, InvoicePaymentI {
    id?: string
    referenceNumber?: string
    externalInvoiceNumber?: string // only for AP invoice
    status: InvoiceStatus
    totalCost: number
    fullPaymentDateTime?: string
    customerName?: string
    vendorName?: string
    supplierStoreName?: string
    additionalDetails?: {
        customerAddress: string
        storeAddress: string
        vendorAddress: string
    }
    lastModifiedAt?: string
    invoicePayable?: boolean
    invoicePayableCategory?: InvoicePayableCategoryT
    expenseType?: InvoiceExpenseTypeT
    invoiceDocumentUrl?: string[]
}

export interface GetInvoiceDetailsResponse extends GetInvoiceApiResponse, InvoiceCustomerI, InvoiceVendorI {
    createdAt?: string
    supplierStoreName?: string
    supplierPhoneNumber?: string
    supplierStoreLink?: string
    supplierStoreAddress?: string
    paymentInfoList?: InvoicePaymentInfoI[]
    products: InvoiceProduct[]
    productTotalCost: number
    shippingCost?: number
    discount?: number
    discountType?: InvoiceDiscountTypeT
    billingNote?: string
}

export interface InvoiceProduct {
    id: string | null
    name: string
    price: number
    discount: number
    discountType?: InvoiceDiscountTypeT
    quantity: number
    totalPrice: number
    wholesalePrices?: ProductWholesale
    stock?: string
}

export interface InvoiceApiCommonResponse {
    result?: boolean
}

export interface updateNonDraftInvoiceParams {
    status: string
    paymentType: InvoicePaymentTypeT
    paymentMethod: InvoicePaymentMethodT
    paymentDateTIme: string
}

export interface InvoiceApiParams {
    billingDateStart?: string
    billingDateEnd?: string
    dueDateStart?: string
    dueDateEnd?: string
    status?: InvoiceStatus | ''
    pageNo?: number
    pageSize?: number
    invoicePayable?: boolean
    category?: InvoiceExpenseTypeT
    paymentType?: InvoicePaymentTypeT
}

export interface CancelInvoiceRequestBody {
    status: string
}

export interface CancelInvoiceResponse {
    result?: boolean
    message?: string
    error?: any
}

export interface PaymentRecordRequest {
    invoiceId: string
    amount: number
    paymentDateTime: string
    paymentType: InvoicePaymentTypeT
    paymentMethod: InvoicePaymentMethodT
}

export type CreatePaymentRecordResponse = APIDataResponse<string>

export type GetInvoiceInfoMethodResponse = DataResponse<GetInvoiceApiResponse[]>
export type GetInvoiceDetailsMethodResponse = DataResponse<GetInvoiceDetailsResponse>
export type updateNonDraftInvoiceParamsResponse = DataResponse<InvoiceApiCommonResponse>

export const InvoiceExpenseTypesMap: { [K in InvoiceExpenseTypeT]: string } = {
    PURCHASE: 'Pembelian',
    'AIR ATAU PDAM': 'Air/PDAM',
    'ALAT TULIS KANTOR': 'Alat Tulis Kantor',
    'GAJI ATAU UPAH': 'Gaji/Upah',
    INTERNET: 'Internet',
    LISTRIK: 'Listrik',
    'PULSA ATAU PAKET DATA': 'Pulsa/Paket Data',
    SEWA: 'Sewa',
    TRANSPORTASI: 'Transportasi',
    LAINNYA: 'Lainnya'
}
