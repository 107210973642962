import dayjs from 'dayjs'
import { useEffect, useMemo, useState } from 'react'
// import { GetInventoryInfo } from '~/data'

import {
    CardWrapper,
    COLORS,
    Icon,
    LineSkeleton,
    PieChart,
    PieChartItemsI,
    PieChartLoader,
    responsiveBreakpointMap,
    Select,
    SelectOption,
    Spacer,
    Table,
    TableBody,
    TableColumn,
    TableRow,
    Tooltip,
    Typography,
    useGetDeviceWidth
} from '@bukuwarung/sachet'
import { formatNumberToCurrencyString } from '@bukuwarung/sachet/utils'
import AnimatedNumber from 'animated-number-react'
import isBetween from 'dayjs/plugin/isBetween'
import { useRouter } from 'next/router'
import { AnalyticsCustomer } from '~/components/Analytics/AnalyticsCustomer'
import { AnalyticsProduct } from '~/components/Analytics/AnalyticsProduct'
import { useGlobal } from '~/components/Global/GlobalProvider'
import Meta from '~/components/Meta'
import { IS_PERSIST_FILTER, STORAGE_WEBVIEW } from '~/constants'
import {
    AnalyticsGraphI,
    AnalyticsInvoicePaymentMethodT,
    AnalyticsPaymentsGraphParamsI,
    AnalyticsSalesGraphParamsI,
    AnalyticsSummaryI,
    AnalyticsSummaryParamsI,
    GetAnalyticsPaymentsGraph,
    GetAnalyticsSalesGraph,
    GetAnalyticsSummary
} from '~/data/accounting'
import { InvoiceExpenseTypesMap, InvoiceExpenseTypeT, InvoicePaymentMethodT } from '~/types/invoice'
import { multiPromiseResolver } from '~/utils/api'
import { closeWebView, delayedCallback, getPercentageBetweenTwo, setOnBackPressed } from '~/utils/common'
import { LineChart, LineChartDataI, LineChartLoader } from '../LineChart'
import { AnalyticsDrawer } from './AnalyticsDrawer'
import { AnalyticsVendor } from './AnalyticsVendor'
dayjs.extend(isBetween)

export const AccountingDashboard = () => {
    const { snackbar } = useGlobal()
    const router = useRouter()
    const [ready, setReady] = useState(false)
    const [filter, setFilter] = useState<AnalyticsSummaryParamsI>({
        startDateTime: dayjs().format('YYYY-MM-DD[T]HH:mm:ss.SSSZ'),
        endDateTime: dayjs().format('YYYY-MM-DD[T]HH:mm:ss.SSSZ'),
        withSalesData: true,
        withMoneyReceivedData: true,
        withDulyPaidData: true,
        withPaymentMethodData: true,
        invoicePayable: false
    })
    const [data, setData] = useState<AnalyticsSummaryI>({})
    const [apData, setApData] = useState<AnalyticsSummaryI>({})
    const [loading, setLoading] = useState(true)
    const [dateOptions] = useState<SelectOption[]>([
        {
            text: 'Bulan ini',
            value: 0
        },
        {
            text: 'Minggu ini',
            value: 1
        },
        {
            text: 'Minggu lalu',
            value: 2
        },
        {
            text: 'Bulan lalu',
            value: 3
        }
    ])
    const [selectedDateOptions, selectDateOptions] = useState(0)
    const [showDrawer, setShowDrawer] = useState<'product' | 'customer' | 'vendor'>()
    const [salesGraph, setSalesGraph] = useState<AnalyticsGraphI>({
        timeFrame: 'DAY',
        revenueReceived: []
    })
    const [paymentsGraph, setPaymentsGraph] = useState<AnalyticsGraphI>({
        timeFrame: 'DAY',
        revenueReceived: []
    })
    const [apSalesGraph, setApSalesGraph] = useState<AnalyticsGraphI>({
        timeFrame: 'DAY',
        revenueReceived: []
    })
    const [apPaymentsGraph, setApPaymentsGraph] = useState<AnalyticsGraphI>({
        timeFrame: 'DAY',
        revenueReceived: []
    })
    const [loadingGraph, setLoadingGraph] = useState(false)
    const [isAndroidWebview, setShowAndroidWebview] = useState(false)

    useEffect(() => {
        initialize()
        setOnBackPressed(closeWebView)
        const isAndroidWebView = localStorage.getItem(STORAGE_WEBVIEW) === 'true'
        setShowAndroidWebview(isAndroidWebView)
    }, [])

    const initialize = () => {
        const checkPersistentFilter = Number(sessionStorage.getItem(IS_PERSIST_FILTER)) || null
        if (checkPersistentFilter !== null) {
            selectDateOptions(() => {
                setReady(true)
                return checkPersistentFilter
            })
            load(checkPersistentFilter)
            return
        }

        setReady(true)
        load(0)
    }

    const getTimeFrame = (timeFrame: AnalyticsGraphI['timeFrame'], selectedDateOptionValue: number) => {
        if (timeFrame === 'MONTH' && [1, 2].includes(selectedDateOptionValue)) {
            return 'WEEK'
        }

        return timeFrame
    }

    const load = async (value: number) => {
        setLoading(true)

        const { startDateTime, endDateTime } = getDateFilter(value)

        setFilter(state => {
            return {
                ...state,
                startDateTime,
                endDateTime
            }
        })

        selectDateOptions(value)

        const [summary, apSummary, salesGraphData, paymentsGraphData, apSalesGraphData, apPaymentsGraphData] =
            await multiPromiseResolver<any>([
                getAnalyticsSummary({ ...filter, startDateTime, endDateTime }),
                getAnalyticsSummary({ ...filter, startDateTime, endDateTime, invoicePayable: true }),
                getAnalyticsSalesGraph({
                    ...{
                        startDate: dayjs(filter.startDateTime).format('YYYY-MM-DD'),
                        endDate: dayjs(filter.endDateTime).format('YYYY-MM-DD')
                    },
                    startDate: dayjs(startDateTime).format('YYYY-MM-DD'),
                    endDate: dayjs(endDateTime).format('YYYY-MM-DD'),
                    timeFrame: getTimeFrame(salesGraph.timeFrame, value)
                }),
                getAnalyticsPaymentsGraph({
                    ...{
                        startDateTime: filter.startDateTime,
                        endDateTime: filter.endDateTime
                    },
                    startDateTime: startDateTime,
                    endDateTime: endDateTime,
                    timeFrame: getTimeFrame(paymentsGraph.timeFrame, value)
                }),
                getAnalyticsSalesGraph({
                    ...{
                        startDate: dayjs(filter.startDateTime).format('YYYY-MM-DD'),
                        endDate: dayjs(filter.endDateTime).format('YYYY-MM-DD')
                    },
                    startDate: dayjs(startDateTime).format('YYYY-MM-DD'),
                    endDate: dayjs(endDateTime).format('YYYY-MM-DD'),
                    timeFrame: getTimeFrame(salesGraph.timeFrame, value),
                    invoicePayable: true
                }),
                getAnalyticsPaymentsGraph({
                    ...{
                        startDateTime: filter.startDateTime,
                        endDateTime: filter.endDateTime
                    },
                    startDateTime: startDateTime,
                    endDateTime: endDateTime,
                    timeFrame: getTimeFrame(paymentsGraph.timeFrame, value),
                    invoicePayable: true
                })
            ])

        delayedCallback(() => {
            setLoading(false)
        }, 500)

        if (
            !!summary.value &&
            !!apSummary.value &&
            !!salesGraphData.value &&
            !!paymentsGraphData.value &&
            !!apSalesGraphData.value &&
            !!apPaymentsGraphData.value
        ) {
            setData(summary.value)
            setApData(apSummary.value)
            setSalesGraph(salesGraphData.value)
            setPaymentsGraph(paymentsGraphData.value)
            setApSalesGraph(apSalesGraphData.value)
            setApPaymentsGraph(apPaymentsGraphData.value)
        }

        checkPageJumper()
    }

    const checkPageJumper = () => {
        const section = router.query.section

        if (!section) {
            return
        }

        const wrapper = document.getElementById('dashboard-wrapper')
        const title = document.getElementById(section as string)

        if (!wrapper || !title) {
            return
        }

        wrapper.scrollTo({
            top: title.offsetTop
        })

        router.push('/')
    }

    const getAnalyticsSummary = async (filter: AnalyticsSummaryParamsI) => {
        let data: AnalyticsSummaryI = {}

        await GetAnalyticsSummary({
            params: filter,
            onSuccess: res => {
                data = res
            },
            onError: message => {
                snackbar.snackbarHandle({
                    message,
                    value: true,
                    style: 'error'
                })
            }
        })

        return data
    }

    const getAnalyticsSalesGraph = async (filter: AnalyticsSalesGraphParamsI) => {
        let data: AnalyticsGraphI = {
            ...salesGraph
        }

        await GetAnalyticsSalesGraph({
            params: filter,
            onSuccess: res => {
                data = res
            },
            onError: message => {
                snackbar.snackbarHandle({
                    message,
                    value: true,
                    style: 'error'
                })
            }
        })

        return data
    }

    const getAnalyticsPaymentsGraph = async (filter: AnalyticsPaymentsGraphParamsI) => {
        let data: AnalyticsGraphI = {
            ...paymentsGraph
        }

        await GetAnalyticsPaymentsGraph({
            params: filter,
            onSuccess: res => {
                data = res
            },
            onError: message => {
                snackbar.snackbarHandle({
                    message,
                    value: true,
                    style: 'error'
                })
            }
        })

        return data
    }

    const onSelectTimeFrame = async (timeFrame: AnalyticsGraphI['timeFrame']) => {
        setLoadingGraph(true)

        const [salesGraphData, paymentsGraphData, apSalesGraphData, apPaymentsGraphData] = await multiPromiseResolver<any>([
            getAnalyticsSalesGraph({
                startDate: dayjs(filter.startDateTime).format('YYYY-MM-DD'),
                endDate: dayjs(filter.endDateTime).format('YYYY-MM-DD'),
                timeFrame
            }),
            getAnalyticsPaymentsGraph({
                startDateTime: filter.startDateTime,
                endDateTime: filter.endDateTime,
                timeFrame
            }),
            getAnalyticsSalesGraph({
                startDate: dayjs(filter.startDateTime).format('YYYY-MM-DD'),
                endDate: dayjs(filter.endDateTime).format('YYYY-MM-DD'),
                timeFrame,
                invoicePayable: true
            }),
            getAnalyticsPaymentsGraph({
                startDateTime: filter.startDateTime,
                endDateTime: filter.endDateTime,
                timeFrame,
                invoicePayable: true
            })
        ])

        delayedCallback(() => {
            setLoadingGraph(false)
        })

        if (!!salesGraphData.value && !!paymentsGraphData.value && !!apSalesGraphData.value && !!apPaymentsGraphData.value) {
            setSalesGraph(salesGraphData.value)
            setPaymentsGraph(paymentsGraphData.value)
            setApSalesGraph(apSalesGraphData.value)
            setApPaymentsGraph(apPaymentsGraphData.value)
        }
    }

    const onSelectDate = (value: number) => {
        sessionStorage.setItem(IS_PERSIST_FILTER, String(value))
        load(value)
    }

    // const percentChanges = (): AnalyticsSummaryI => {
    //     return {
    //         totalSales: getPercentageDifference(data.totalSales || 0, compareData.totalSales || 0),
    //         totalMoneyReceived: getPercentageDifference(data.totalMoneyReceived || 0, compareData.totalMoneyReceived || 0),
    //         totalPaidSales: getPercentageDifference(data.totalPaidSales || 0, compareData.totalPaidSales || 0),
    //         totalUnpaidSales: getPercentageDifference(data.totalUnpaidSales || 0, compareData.totalUnpaidSales || 0)
    //     }
    // }

    const profit = (data.totalSales || 0) - (apData.totalSales || 0)
    const isProfit = profit >= 0

    return (
        <>
            <Meta title="Dashboard"></Meta>
            <div className="h-full w-full" data-testid="accounting-dashboard">
                <div className="flex h-full w-full flex-col">
                    <div className="relative flex flex-grow flex-col overflow-hidden">
                        {isAndroidWebview && (
                            <div className="flex items-center p-4 font-bold text-white" style={{ background: '#0091FF' }}>
                                <Icon name="x" size="xl" onClick={() => closeWebView()}></Icon>
                                <h3 className="ml-3 text-lg">Statistik Invoice</h3>
                            </div>
                        )}
                        <div className="flex-grow overflow-auto p-4 sm:p-5" id="dashboard-wrapper">
                            <div className={`hidden items-center sm:flex ${!isProfit ? 'text-red-400' : 'text-green-400'}`}>
                                {/* <Icon name={percentChanges().totalSales! < 0 ? 'chevron-down' : 'chevron-up'} size="xl"></Icon> */}
                                {/* <div className="flex items-end">
                                    <span className="mb-1 mr-2 truncate text-neutrals-900">{isProfit ? 'Untung' : 'Rugi'}</span>
                                    <Typography
                                        size="3xl"
                                        fontWeight="bold"
                                        color={!isProfit ? COLORS.SEMANTIC.RED[400] : COLORS.SEMANTIC.GREEN[400]}>
                                        <AnimatedNumber
                                            duration={1500}
                                            value={profit}
                                            formatValue={(value: number) =>
                                                'Rp' + formatNumberToCurrencyString(Math.floor(isProfit ? value : value * -1))
                                            }></AnimatedNumber>
                                    </Typography>

                                    <span className="mb-1 ml-2 truncate text-neutrals-900">pada periode ini</span>
                                </div> */}
                            </div>
                            <div className="flex w-full max-w-7xl items-center" data-testid="homepage-header">
                                <h3 className="text-xl font-bold text-neutrals-800 sm:text-2xl" id="pemasukan">
                                    Pemasukan
                                </h3>
                                <Spacer></Spacer>
                                <div className="flex items-center" data-testid="filter">
                                    <Select options={dateOptions} width={180} value={selectedDateOptions} onSelect={onSelectDate}></Select>
                                </div>
                            </div>

                            <div className="my-4 flex w-full max-w-7xl flex-col sm:my-5 xl:flex-row">
                                <div className="hidden w-full max-w-full flex-shrink-0 grid-cols-1 gap-4 sm:grid sm:grid-cols-2 sm:gap-5 xl:w-[300px] xl:grid-cols-1">
                                    {Summary(
                                        loading,
                                        'Penjualan',
                                        `Penjualan adalah total nilai dari\nsemua invoice yang telah dibuat\ndalam periode terpilih.`,
                                        data.totalSales
                                    )}
                                    {Summary(
                                        loading,
                                        'Terbayar',
                                        'Terbayar adalah total invoice yang\ntelah di bayar berdasarkan tanggal\ninvoice dibuat dalam periode terpilih.',
                                        data.totalPaidSales
                                    )}
                                    {Summary(
                                        loading,
                                        'Belum Dibayar',
                                        'Belum Dibayar adalah total invoice yang\nbelum di bayar berdasarkan taggal\ninvoice dibuat dalam periode terpilih.',
                                        data.totalUnpaidSales
                                    )}
                                    {Summary(
                                        loading,
                                        'Uang Diterima',
                                        'Uang Diterima adalah pembayaran\ndari invoice dalam periode terpilih\nyang di hitung berdasarkan tanggal pembayaran.',
                                        data.totalMoneyReceived
                                    )}
                                </div>
                                <div className="block sm:hidden">
                                    {SummaryMobile(loading, [
                                        {
                                            title: 'Penjualan',
                                            tooltip:
                                                'Penjualan adalah total nilai dari\nsemua invoice yang telah dibuat\ndalam periode terpilih.',
                                            value: data.totalSales || 0
                                        },
                                        {
                                            title: 'Terbayar',
                                            tooltip:
                                                'Terbayar adalah total invoice yang\ntelah di bayar berdasarkan tanggal\ninvoice dibuat dalam periode terpilih.',
                                            value: data.totalPaidSales || 0
                                        },
                                        {
                                            title: 'Belum Dibayar',
                                            tooltip:
                                                'Belum Dibayar adalah total invoice yang\nbelum di bayar berdasarkan taggal\ninvoice dibuat dalam periode terpilih.',
                                            value: data.totalUnpaidSales || 0
                                        },
                                        {
                                            title: 'Uang Diterima',
                                            tooltip:
                                                'Uang Diterima adalah pembayaran\ndari invoice dalam periode terpilih\nyang di hitung berdasarkan tanggal pembayaran.',
                                            value: data.totalMoneyReceived || 0
                                        }
                                    ])}
                                </div>
                                <div className="h-4 w-0 flex-shrink-0 xl:h-0 xl:w-5"></div>

                                <div className="flex w-full flex-shrink items-stretch">
                                    {renderLineChart(
                                        [salesGraph.revenueReceived, paymentsGraph.revenueReceived],
                                        salesGraph.timeFrame,
                                        'Penjualan & Uang Diterima',
                                        loading || !ready || loadingGraph,
                                        selectedDateOptions,
                                        ['Penjualan', 'Uang Diterima'],
                                        onSelectTimeFrame
                                    )}
                                </div>
                            </div>

                            <div className={'block'}>
                                <h3 className="mb-5 pt-3 text-xl font-bold text-neutrals-800 sm:text-2xl" id="pengeluaran">
                                    Pengeluaran
                                </h3>
                                <div className="my-4 flex w-full max-w-7xl flex-col sm:my-5 xl:flex-row">
                                    <div className="hidden w-full max-w-full flex-shrink-0 grid-cols-1 gap-4 sm:grid sm:grid-cols-2 sm:gap-5 xl:w-[300px] xl:grid-cols-1">
                                        {Summary(
                                            loading,
                                            'Pengeluaran',
                                            `Pengeluaran adalah total nilai dari\nsemua invoice yang telah dibuat\ndalam periode terpilih.`,
                                            apData.totalSales
                                        )}
                                        {Summary(
                                            loading,
                                            'Terbayar',
                                            'Terbayar adalah total invoice yang\ntelah di bayar berdasarkan tanggal\ninvoice dibuat dalam periode terpilih.',
                                            apData.totalPaidSales
                                        )}
                                        {Summary(
                                            loading,
                                            'Belum Dibayar',
                                            'Belum Dibayar adalah total invoice yang\nbelum di bayar berdasarkan taggal\ninvoice dibuat dalam periode terpilih.',
                                            apData.totalUnpaidSales
                                        )}
                                        {Summary(
                                            loading,
                                            'Uang Dikirim',
                                            'Uang Dikirim adalah pembayaran\ndari invoice dalam periode terpilih\nyang di hitung berdasarkan tanggal pembayaran.',
                                            apData.totalMoneyReceived
                                        )}
                                    </div>
                                    <div className="block sm:hidden">
                                        {SummaryMobile(loading, [
                                            {
                                                title: 'Pengeluaran',
                                                tooltip:
                                                    'Penjualan adalah total nilai dari\nsemua invoice yang telah dibuat\ndalam periode terpilih.',
                                                value: data.totalSales || 0
                                            },
                                            {
                                                title: 'Terbayar',
                                                tooltip:
                                                    'Terbayar adalah total invoice yang\ntelah di bayar berdasarkan tanggal\ninvoice dibuat dalam periode terpilih.',
                                                value: data.totalPaidSales || 0
                                            },
                                            {
                                                title: 'Belum Dibayar',
                                                tooltip:
                                                    'Belum Dibayar adalah total invoice yang\nbelum di bayar berdasarkan taggal\ninvoice dibuat dalam periode terpilih.',
                                                value: data.totalUnpaidSales || 0
                                            },
                                            {
                                                title: 'Uang Dikirim',
                                                tooltip:
                                                    'Uang Diterima adalah pembayaran\ndari invoice dalam periode terpilih\nyang di hitung berdasarkan tanggal pembayaran.',
                                                value: data.totalMoneyReceived || 0
                                            }
                                        ])}
                                    </div>
                                    <div className="h-4 w-0 flex-shrink-0 xl:h-0 xl:w-5"></div>

                                    <div className="flex w-full flex-shrink items-stretch">
                                        {renderLineChart(
                                            [apSalesGraph.revenueReceived, apPaymentsGraph.revenueReceived],
                                            salesGraph.timeFrame,
                                            'Pengeluaran & Uang Dikirim',
                                            loading || !ready || loadingGraph,
                                            selectedDateOptions,
                                            ['Pengeluaran', 'Uang Dikirim'],
                                            onSelectTimeFrame
                                        )}
                                    </div>
                                </div>
                            </div>

                            <h3 className="mb-5 pt-3 text-2xl font-bold text-neutrals-800">Informasi Lain</h3>

                            {ready && (
                                <div className="mb-4 grid w-full max-w-7xl grid-cols-1 gap-4 sm:mb-5 sm:gap-5 md:grid-cols-2">
                                    {renderPieChart(
                                        getPayableCategoryCount(
                                            apData.invoicePayableCategoryCount,
                                            getTotalPayableInvoice(apData.invoicePayableCategoryCount)
                                        ),
                                        getTotalPayableInvoice(apData.invoicePayableCategoryCount),
                                        'Kategori Pengeluaran',
                                        'Komposisi kategori pengeluaran berdasarkan nilai\nyang ada pada invoice yang tercatat.',
                                        loading || !ready
                                    )}
                                    <CardWrapper fullWidth>
                                        <AnalyticsProduct
                                            pageSize={3}
                                            startDateTime={filter.startDateTime}
                                            endDateTime={filter.endDateTime}
                                            onClickMore={() => setShowDrawer('product')}></AnalyticsProduct>
                                    </CardWrapper>

                                    <CardWrapper fullWidth>
                                        <AnalyticsVendor
                                            pageSize={3}
                                            startDateTime={filter.startDateTime}
                                            endDateTime={filter.endDateTime}
                                            onClickMore={() => setShowDrawer('vendor')}></AnalyticsVendor>
                                    </CardWrapper>

                                    <CardWrapper fullWidth>
                                        <AnalyticsCustomer
                                            pageSize={3}
                                            startDateTime={filter.startDateTime}
                                            endDateTime={filter.endDateTime}
                                            onClickMore={() => setShowDrawer('customer')}></AnalyticsCustomer>
                                    </CardWrapper>
                                </div>
                            )}
                        </div>
                        <AnalyticsDrawer
                            key={showDrawer}
                            show={!!showDrawer}
                            title={showDrawer === 'customer' ? 'Pembeli' : showDrawer === 'vendor' ? 'Vendor' : `Produk Terjual`}
                            onDrawerClose={() => setShowDrawer(undefined)}>
                            {showDrawer === 'product' ? (
                                <AnalyticsProduct
                                    type="detail"
                                    startDateTime={filter.startDateTime}
                                    endDateTime={filter.endDateTime}
                                    title={false}
                                    className="flex-grow"></AnalyticsProduct>
                            ) : showDrawer === 'vendor' ? (
                                <AnalyticsVendor
                                    type="detail"
                                    startDateTime={filter.startDateTime}
                                    endDateTime={filter.endDateTime}
                                    title={false}
                                    className="flex-grow"></AnalyticsVendor>
                            ) : (
                                <AnalyticsCustomer
                                    type="detail"
                                    startDateTime={filter.startDateTime}
                                    endDateTime={filter.endDateTime}
                                    title={false}
                                    className="flex-grow"></AnalyticsCustomer>
                            )}
                        </AnalyticsDrawer>
                    </div>
                </div>
            </div>
        </>
    )
}

export const Summary = (loading: boolean, title: string, tooltip: string, value = 0) => {
    return (
        <CardWrapper fullWidth paddingSize="sm">
            <Typography variant="h3" fontWeight="medium" size="lg" className="mb-3 flex items-center">
                <span className="mr-1">{title}</span>
                <Tooltip position="bottom" content={<span className="font-normal">{tooltip}</span>} variant="lighter" trigger="click">
                    <span className="flex h-6 w-6 items-center justify-center rounded-full hover:bg-neutrals-200">
                        <Icon name="question-mark-outline" width={16} color={COLORS.SEMANTIC.BLUE[500]}></Icon>
                    </span>
                </Tooltip>
            </Typography>
            <>
                {loading ? (
                    <LineSkeleton count={1} size="3xl" className="w-32" />
                ) : (
                    <AnimatedNumber
                        duration={200}
                        value={value}
                        formatValue={(value: number) => (
                            <Typography size="2xl" fontWeight="bold">
                                Rp{formatNumberToCurrencyString(Math.floor(value))}
                            </Typography>
                        )}></AnimatedNumber>
                )}
            </>
        </CardWrapper>
    )
}

export const SummaryMobile = (loading: boolean, data: { title: string; tooltip: string; value: number }[]) => {
    return (
        <CardWrapper fullWidth paddingSize="sm">
            <div className="grid grid-cols-1 gap-4">
                {data.map((item, index) => (
                    <div key={index} className={`${index === data.length - 1 ? '' : 'border-b border-neutrals-200 pb-4'}`}>
                        <Typography variant="h3" size="base" className="mb-1 flex items-center">
                            <span className="mr-1 text-neutrals-500">{item.title}</span>
                            <Tooltip position="bottom" content={<span className="font-normal">{item.tooltip}</span>} variant="lighter">
                                <span className="flex h-6 w-6 items-center justify-center rounded-full hover:bg-neutrals-200">
                                    <Icon name="question-mark-outline" width={16} color={COLORS.SEMANTIC.BLUE[500]}></Icon>
                                </span>
                            </Tooltip>
                        </Typography>
                        <>
                            {loading ? (
                                <LineSkeleton count={1} size="3xl" className="w-32" />
                            ) : (
                                <AnimatedNumber
                                    duration={200}
                                    value={item.value || 0}
                                    formatValue={(value: number) => (
                                        <Typography size="xl" fontWeight="bold">
                                            Rp{formatNumberToCurrencyString(Math.floor(value))}
                                        </Typography>
                                    )}></AnimatedNumber>
                            )}
                        </>
                    </div>
                ))}
            </div>
        </CardWrapper>
    )
}

/**
 * Get date filter based on value
 * @param value
 */
const getDateFilter = (value: number) => {
    const today = dayjs()
    const currentMonthStart = today.startOf('month')
    const currentWeekStart = today.startOf('week')
    const lastMonthStart = today.startOf('month').subtract(1, 'day').startOf('month')
    const lastWeekStart = today.startOf('week').subtract(1, 'day').startOf('week')
    const differenceCurrentMonth = today.diff(currentMonthStart, 'day')
    const differenceCurrentWeek = today.diff(currentWeekStart, 'day')

    switch (value) {
        case 0:
            return {
                startDateTime: today.startOf('month').format('YYYY-MM-DD[T]HH:mm:ss.SSSZ'),
                endDateTime: today.endOf('month').format('YYYY-MM-DD[T]HH:mm:ss.SSSZ'),
                compareStartDateTime: lastMonthStart.format('YYYY-MM-DD[T]HH:mm:ss.SSSZ'),
                compareEndDateTime: lastMonthStart.add(differenceCurrentMonth, 'day').format('YYYY-MM-DD[T]HH:mm:ss.SSSZ')
            }
        case 1:
            return {
                startDateTime: today.startOf('week').format('YYYY-MM-DD[T]HH:mm:ss.SSSZ'),
                endDateTime: today.endOf('week').format('YYYY-MM-DD[T]HH:mm:ss.SSSZ'),
                compareStartDateTime: lastWeekStart.format('YYYY-MM-DD[T]HH:mm:ss.SSSZ'),
                compareEndDateTime: lastWeekStart.add(differenceCurrentWeek, 'day').format('YYYY-MM-DD[T]HH:mm:ss.SSSZ')
            }
        case 2:
            const previousWeekStart = lastWeekStart.subtract(1, 'day').startOf('week')
            return {
                startDateTime: lastWeekStart.format('YYYY-MM-DD[T]HH:mm:ss.SSSZ'),
                endDateTime: lastWeekStart.endOf('week').format('YYYY-MM-DD[T]HH:mm:ss.SSSZ'),
                compareStartDateTime: previousWeekStart.format('YYYY-MM-DD[T]HH:mm:ss.SSSZ'),
                compareEndDateTime: previousWeekStart.add(differenceCurrentWeek, 'day').format('YYYY-MM-DD[T]HH:mm:ss.SSSZ')
            }
        case 3:
            const previousMonthStart = lastMonthStart.subtract(1, 'day').startOf('month')
            return {
                startDateTime: lastMonthStart.format('YYYY-MM-DD[T]HH:mm:ss.SSSZ'),
                endDateTime: lastMonthStart.endOf('month').format('YYYY-MM-DD[T]HH:mm:ss.SSSZ'),
                compareStartDateTime: previousMonthStart.format('YYYY-MM-DD[T]HH:mm:ss.SSSZ'),
                compareEndDateTime: previousMonthStart.add(differenceCurrentMonth, 'day').format('YYYY-MM-DD[T]HH:mm:ss.SSSZ')
            }
    }

    return {
        startDateTime: today.startOf('month').format('YYYY-MM-DD[T]HH:mm:ss.SSSZ'),
        endDateTime: today.endOf('month').format('YYYY-MM-DD[T]HH:mm:ss.SSSZ'),
        compareStartDateTime: lastMonthStart.format('YYYY-MM-DD[T]HH:mm:ss.SSSZ'),
        compareEndDateTime: lastMonthStart.add(differenceCurrentMonth, 'day').format('YYYY-MM-DD[T]HH:mm:ss.SSSZ')
    }
}

export const PIE_COLORS = ['#4C7ACD', '#023075', '#FFDE68', '#E4A600', '#F78A1D', '#99C24D', '#A68CC1', '#47B1BC', '#F2675C', '#A1A1A1']

/**
 * Get on time payment data
 * @param {number} totalOnTime - Total on time payment
 * @param {number} totalLate - Total late payment
 * @param {number} total - Total payment
 * @returns {PieChartItemsI[]} - Pie chart data items
 */
export const getOnTimePaymentData = (totalOnTime: number, totalLate: number, total: number) => {
    const items = [
        {
            text: 'Tepat Waktu',
            value: totalOnTime,
            percent: getPercentageBetweenTwo(totalOnTime, total),
            color: '#47B1BC'
        },
        {
            text: 'Terlambat',
            value: totalLate,
            percent: getPercentageBetweenTwo(totalLate, total),
            color: '#F2675C'
        }
    ]

    return items
}

/**
 * Get payment method data
 * @param {AnalyticsInvoicePaymentMethodT} data - Payment method data
 * @param {number} total - Total payment
 * @returns {PieChartItemsI[]} - Pie chart data items
 */
export const getPaymentMethodData = (data: AnalyticsInvoicePaymentMethodT, total: number) => {
    const items: PieChartItemsI[] = []
    let index = 0

    for (const item in data) {
        items.push({
            color: PIE_COLORS[index],
            text: item,
            value: data[item as InvoicePaymentMethodT],
            percent: getPercentageBetweenTwo(data[item as InvoicePaymentMethodT], total)
        })
        index++
    }

    return items
}

export const getTotalPayableInvoice = (data: AnalyticsSummaryI['invoicePayableCategoryCount']) => {
    if (!data) {
        return 0
    }

    return Object.values(data).reduce((a, b) => a + b, 0)
}

/**
 * Get payable invoice category data
 * @param {AnalyticsSummaryI['invoicePayableCategoryCount']} data - payable invoice category data
 * @param {number} total - Total payment
 * @returns {PieChartItemsI[]} - Pie chart data items
 */
export const getPayableCategoryCount = (data: AnalyticsSummaryI['invoicePayableCategoryCount'], total: number) => {
    const items: PieChartItemsI[] = []
    let index = 0

    for (const item in data) {
        items.push({
            color: PIE_COLORS[index],
            text: InvoiceExpenseTypesMap[item as InvoiceExpenseTypeT],
            value: data[item as InvoiceExpenseTypeT],
            percent: getPercentageBetweenTwo(data[item as InvoiceExpenseTypeT], total)
        })
        index++
    }

    return items
}

/**
 * Render pie chart
 * @param {PieChartItemsI[]} data - Pie chart data items
 * @param {number} count - Total count of data
 * @param {string} title - Title of the chart
 * @param {string} tooltip - Tooltip of the chart title
 * @param {boolean} loading - Loading state
 * @returns {JSX.Element} - Rendered pie chart
 */
export const renderPieChart = (data: PieChartItemsI[], count: number, title: string, tooltip: string, loading: boolean) => {
    const renderContent = () => {
        if (loading) {
            return <PieChartLoader width={220} height={220}></PieChartLoader>
        }

        if (count === 0) {
            return (
                <Typography variant="p" className="mt-4 w-full sm:mt-5">
                    Tidak ada data.
                </Typography>
            )
        }

        return (
            <div className="flex w-full flex-col items-center xl:flex-row xl:items-start">
                <PieChart items={data}></PieChart>
                <Table>
                    <TableBody>
                        {data.map(item => (
                            <TableRow key={item.text}>
                                <TableColumn>
                                    <div className="flex items-center">
                                        <span
                                            className="mr-4 block h-4 w-4 rounded"
                                            style={{
                                                background: item.color
                                            }}></span>

                                        <span className="font-normal">{item.text}</span>
                                    </div>
                                </TableColumn>
                                <TableColumn>
                                    <span className="font-normal">{formatNumberToCurrencyString(item.value)}</span>
                                </TableColumn>
                                <TableColumn>
                                    <p className="text-right font-normal">{Number(item.percent.toFixed(2))}%</p>
                                </TableColumn>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </div>
        )
    }

    return (
        <CardWrapper fullWidth>
            <h3 className="flex items-center text-lg text-neutrals-900">
                <span className="mr-1 font-medium">{title}</span>
                <Tooltip position="top" content={tooltip} variant="lighter">
                    <span className="flex h-6 w-6 items-center justify-center rounded-full hover:bg-neutrals-200">
                        <Icon name="question-mark-outline" width={16} color={COLORS.SEMANTIC.BLUE[500]}></Icon>
                    </span>
                </Tooltip>
            </h3>
            <div className="flex flex-col items-center">{renderContent()}</div>
        </CardWrapper>
    )
}

export const renderLineChart = (
    data: [AnalyticsGraphI['revenueReceived'], AnalyticsGraphI['revenueReceived']],
    timeFrame: AnalyticsGraphI['timeFrame'],
    title: string,
    loading: boolean,
    selectedDateOptions: number,
    labels: [string, string],
    onSelectTimeFrame: (timeFrame: AnalyticsGraphI['timeFrame']) => void
) => {
    const [deviceWidth, setDeviceWidth] = useState(0)

    const filteredDataSales = useMemo(() => {
        if (data.length < 2) {
            return data[0]
        }

        const dataSales = data[0]
        const dataPayments = data[1]

        if (dataSales.length === 0 || dataPayments.length === 0) {
            return data[0]
        }

        const filteredData = dataSales.filter(item => {
            // return only data that matches startDate and endDate in data payments
            return dataPayments.some(payment => {
                return item.startDate === payment.startDate && item.endDate === payment.endDate
            })
        })

        return filteredData
    }, [data])

    useGetDeviceWidth(setDeviceWidth)

    const isMobile = deviceWidth <= responsiveBreakpointMap.sm

    const getPaymentsRevenueByDate = (startDate: string, endDate: string) => {
        return (
            data[1].find(item => {
                return item.startDate === startDate && item.endDate === endDate
            })?.totalRevenue || 0
        )
    }

    const chartData: LineChartDataI = useMemo(() => {
        return {
            labels,
            sets: filteredDataSales.map(item => ({
                values: [item.totalRevenue, getPaymentsRevenueByDate(item.startDate, item.endDate)],
                ...getLineChartItem(timeFrame, item.startDate, item.endDate, item.totalRevenue)
            }))
        }
    }, [filteredDataSales])

    const timeFrameOptions = useMemo(() => {
        const options: SelectOption[] = [
            {
                text: 'Hari',
                value: 'DAY'
            },
            {
                text: 'Minggu',
                value: 'WEEK'
            }
        ]
        if ([0, 3].includes(selectedDateOptions)) {
            options.push({
                text: 'Bulan',
                value: 'MONTH'
            })
        }
        return options
    }, [selectedDateOptions])

    const renderContent = () => {
        if (loading) {
            return <LineChartLoader></LineChartLoader>
        }

        if (chartData.sets.length === 0) {
            return (
                <Typography variant="p" className="mt-4 w-full sm:mt-5">
                    Tidak ada data.
                </Typography>
            )
        }

        return (
            <div className="flex w-full min-w-[650px] flex-col items-center xl:flex-row xl:items-start">
                <LineChart
                    data={chartData}
                    minUpperBound={900}
                    minHeight={isMobile ? 300 : undefined}
                    formatYTicks={value => `Rp${formatNumberToCurrencyString(Math.floor(value))}`}></LineChart>
            </div>
        )
    }

    return (
        <CardWrapper fullWidth>
            <h3 className="mb-5 flex items-center text-lg text-neutrals-900 sm:mb-4">
                <span className="mr-1 sm:font-medium">{title}</span>
                <Spacer></Spacer>
                <Select
                    width={120}
                    placeholder="Pilih periode"
                    options={timeFrameOptions}
                    value={timeFrame}
                    onSelect={value => onSelectTimeFrame(value)}></Select>
            </h3>
            <div className="overflow-auto">{renderContent()}</div>
        </CardWrapper>
    )
}

const getLineChartItem = (timeFrame: AnalyticsGraphI['timeFrame'], startDate: string, endDate: string, totalRevenue: number) => {
    const start = dayjs(startDate)
    const end = dayjs(endDate)
    let xTickName

    switch (timeFrame) {
        case 'DAY':
            xTickName = `${start.format('DD MMM')} `
            return {
                xTickName,
                tooltip: (
                    <div className="w-64 rounded text-sm">
                        <h3 className="border-b border-neutral-200 bg-neutrals-100 p-4">{xTickName}</h3>
                        <div className="p-4 font-semibold">{totalRevenue}</div>
                    </div>
                )
            }
        case 'WEEK':
            xTickName = `${start.format('DD MMM')} - ${end.subtract(1, 'day').format('DD MMM')}`
            return {
                xTickName,
                tooltip: (
                    <div className="w-64 rounded text-sm">
                        <h3 className="border-b border-neutral-200 bg-neutrals-100 p-4">{xTickName}</h3>
                        <div className="p-4 font-semibold">{totalRevenue}</div>
                    </div>
                )
            }
        case 'MONTH':
            xTickName = `${start.format('MMM YYYY')}`
            return {
                xTickName,
                tooltip: (
                    <div className="w-64 rounded text-sm">
                        <h3 className="border-b border-neutral-200 bg-neutrals-100 p-4">{xTickName}</h3>
                        <div className="p-4 font-semibold">{totalRevenue}</div>
                    </div>
                )
            }
    }
}
