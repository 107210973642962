import { COLORS } from '@bukuwarung/sachet'
import { Fragment, useEffect, useMemo, useRef, useState } from 'react'

const MIN_HEIGHT = 380
const MIN_WIDTH = 300
const HEIGHT_RATIO = 0.33602941176471
const Y_TICKS_COUNT = 5
const FONT_SIZE = 14
const X_TICKS_WRAPPER_HEIGHT = 28
const SPACE = 40
const POINT_SIZE = 6
const ANIMATION_SPEED = 1
const MIN_UPPER_BOUND = 1000
const MIN_LOWER_BOUND = 0
const TOOLTIP_SPACE = 20
const TOOLTIP_WIDTH = 256
const COLORS_SCHEME = [
    COLORS.MAIN.PRIMARY.DEFAULT,
    COLORS.MAIN.SECONDARY.DEFAULT,
    COLORS.SEMANTIC.AMBER.DEFAULT,
    COLORS.SEMANTIC.GREEN.DEFAULT,
    COLORS.SEMANTIC.BLUE.DEFAULT,
    COLORS.SEMANTIC.RED.DEFAULT
]

export interface LineChartDataI {
    labels: string[]
    sets: LineChartItemsI[]
}

interface LineChartItemsI {
    values: number[]
    xTickName: string
}

interface LineChartProps {
    /**
     * Data to be displayed in the chart
     */
    data: LineChartDataI
    /**
     * Minimum width of the chart
     * @default 300
     */
    minWidth?: number
    /**
     * Minimum height of the chart
     * @default 380
     */
    minHeight?: number
    /**
     * Height ratio of the chart
     * @default 0.33602941176471
     */
    heightRatio?: number
    /**
     * Number of ticks on the y-axis
     * @default 5
     */
    yTicksCount?: number
    /**
     * Font size of the chart
     * @default 14
     */
    fontSize?: number
    /**
     * X-axis ticks wrapper height
     * @default 40
     */
    xTicksWrapperHeight?: number
    /**
     * space between x to y axis wrapper
     * @default 20
     */
    space?: number
    /**
     * point size
     * @default 4
     */
    pointSize?: number
    /**
     * animation speed
     * @default 1
     */
    animationSpeed?: number
    /**
     * Minimum upper value of the y-axis
     * @default 1000
     */
    minUpperBound?: number
    /**
     * Minimum lower value of the y-axis
     * @default 0
     */
    minLowerBound?: number
    /**
     * Space between tooltip and the points
     * @default 20
     */
    tooltipSpace?: number
    /**
     * Colors scheme of the chart
     * @default COLORS_SCHEME
     */
    /**
     * Tooltip width
     * @default 256
     */
    tooltipWidth?: number
    colors?: string[]
    /**
     * Optional format y ticks label
     * @param {number} value
     * @param {number} interval
     * @returns {string} label
     */
    formatYTicks?: (value: number, interval: number) => string
    /**
     * Optional format y ticks label
     * @param {number} value
     * @param {number} interval
     * @returns {string} label
     */
    formatXTicks?: (value: string, index: number) => string
    /**
     * Optional format y ticks label
     * @param {string} value
     * @returns {JSX.Element | string} label
     */
    formatLegends?: (value: string, label: string, colors: string) => JSX.Element | string
}

interface YTick {
    value: number
    label: string
    y: number
}

interface XTick {
    name: string
    x: number
}

interface Point {
    x: number
    y: number
    value: number
    label: string
    color: string
    xTickName: string
}

export const LineChart = ({
    data = {
        labels: [],
        sets: []
    },
    minWidth = MIN_WIDTH,
    minHeight = MIN_HEIGHT,
    heightRatio = HEIGHT_RATIO,
    yTicksCount = Y_TICKS_COUNT,
    fontSize = FONT_SIZE,
    xTicksWrapperHeight = X_TICKS_WRAPPER_HEIGHT,
    space = SPACE,
    pointSize = POINT_SIZE,
    animationSpeed = ANIMATION_SPEED,
    minUpperBound = MIN_UPPER_BOUND,
    minLowerBound = MIN_LOWER_BOUND,
    tooltipSpace = TOOLTIP_SPACE,
    tooltipWidth = TOOLTIP_WIDTH,
    colors = COLORS_SCHEME,
    formatYTicks = value => String(value),
    formatXTicks = value => value,
    formatLegends = (value, label, color) => (
        <div className="flex items-center p-2">
            <div className="mr-2 h-3.5 w-3.5 rounded-full" style={{ backgroundColor: color }}></div>
            <div className="mr-2">{label} :</div>
            <div className="font-bold">{value}</div>
        </div>
    )
}: LineChartProps) => {
    const mainWrapper = useRef<HTMLDivElement | null>(null)
    const yWrapper = useRef<SVGGElement | null>(null)
    const xWrapper = useRef<SVGGElement | null>(null)

    const [yTicks, setYTicks] = useState<YTick[]>(() =>
        data.sets.map(item => {
            const value = item.values[0] | 0
            return {
                value: value,
                label: formatYTicks(value, value),
                y: 0
            }
        })
    )
    const [xTicks, setXTicks] = useState<XTick[]>(() =>
        data.sets.map((item, index) => ({
            name: formatXTicks(item.xTickName, index),
            x: 0
        }))
    )

    const frameWidth = Math.max(mainWrapper.current?.getBoundingClientRect().width || 0, minWidth)
    const frameHeight = Math.max(frameWidth * heightRatio, minHeight)

    const yWrapperProps = useMemo(() => {
        const { width, height } = yWrapper.current?.getBoundingClientRect() || {
            width: 0,
            height: 0
        }
        return {
            x: width,
            width,
            height
        }
    }, [yTicks, yWrapper, frameWidth, frameHeight])

    const xTickElementWidth = useMemo(() => {
        const elements = xWrapper.current?.querySelectorAll('text') || []
        let width = 0
        elements.forEach(element => {
            const elementWidth = element.getBoundingClientRect().width
            if (elementWidth > width) {
                width = elementWidth
            }
        })
        return width
    }, [xTicks, xWrapper, frameWidth, frameHeight])

    const visualWidth = frameWidth - yWrapperProps.width - space
    const visualHeight = frameHeight - xTicksWrapperHeight - space
    const lineWidth = visualWidth - xTickElementWidth / 2
    const lineHeight = visualHeight + fontSize

    const [lineMark, setLineMark] = useState<Point[]>([])
    const [showLineMark, setShowLineMark] = useState(false)
    const tooltipRef = useRef<HTMLDivElement>(null)

    const getRange = () => {
        const sortedData = [...data.sets]
            .map(item => item.values)
            .reduce((a, b) => a.concat(b), [])
            .sort((a, b) => a - b)
        const upperBound = Math.max(sortedData[sortedData.length - 1], minUpperBound)
        const lowerBound = sortedData[0] === upperBound ? minLowerBound : sortedData[0]
        const range = upperBound - lowerBound

        return {
            upperBound,
            lowerBound,
            range
        }
    }

    const getLabel = (index: number) => {
        return data.labels[index] || `data ${index + 1}`
    }

    const getColor = (index: number) => {
        return colors[index] || COLORS.MAIN.NEUTRALS.DEFAULT
    }

    const points = useMemo(() => {
        const { lowerBound, range } = getRange()

        const interval = data.sets.length - 1 === 0 ? 0 : lineWidth / (data.sets.length - 1)
        const yCoordinateInterval = visualHeight / range

        return data.sets.map((item, index) => {
            const x: number = interval * index

            return item.values.map((value, index) => {
                const y = visualHeight - (value - lowerBound) * yCoordinateInterval + fontSize

                return {
                    x,
                    y,
                    value,
                    label: getLabel(index),
                    color: getColor(index),
                    xTickName: item.xTickName
                }
            })
        })
    }, [yTicks, xTicks, lineWidth, visualHeight])

    useEffect(() => {
        calculateYTicks()
        calculateXTicks()
    }, [visualHeight, lineWidth])

    const calculateYTicks = () => {
        const { lowerBound, range } = getRange()

        const denominators = yTicksCount - 1
        const interval = range / denominators
        const yCoordinateInterval = visualHeight / denominators

        const ticks = []

        for (let i = 0; i < yTicksCount; i++) {
            const tick = interval * i + lowerBound
            const yCoordinate = visualHeight - yCoordinateInterval * i
            ticks.push({
                value: tick,
                label: formatYTicks(tick, interval),
                y: yCoordinate
            })
        }

        setYTicks(ticks)
    }

    const calculateXTicks = () => {
        const xCoordinateInterval = data.sets.length - 1 === 0 ? 0 : lineWidth / (data.sets.length - 1)

        const ticks = []

        for (let i = 0; i < data.sets.length; i++) {
            const xCoordinate = xCoordinateInterval * i
            ticks.push({
                name: formatXTicks(data.sets[i].xTickName, i),
                x: xCoordinate
            })
        }

        setXTicks(ticks)
    }

    const drawLine = () => {
        return points.reduce((acc: string[], item, index) => {
            if (index === 0) {
                return item.map(point => `M ${point.x} ${point.y}`)
            }

            return item.map((point, i) => {
                const prevPoint = points[index - 1][i]
                const x1 = prevPoint.x + (point.x - prevPoint.x) / 2
                const y1 = prevPoint.y
                const x2 = point.x - (point.x - prevPoint.x) / 2
                const y2 = point.y

                return `${acc[i]} C ${x1} ${y1}, ${x2} ${y2}, ${point.x} ${point.y}`
            })
        }, [])
    }

    const getTooltipPosition = () => {
        const x = lineMark[0]?.x || 0

        const elementWidth = tooltipRef.current?.clientWidth || tooltipWidth
        const tooltipRightOffset = elementWidth + x
        if (tooltipRightOffset > visualWidth) {
            return `translate(${x - elementWidth - tooltipSpace}, ${0})`
        }

        return `translate(${x + tooltipSpace}, ${0})`
    }

    const renderYTicks = () => {
        return (
            <g ref={yWrapper} transform={`translate(${yWrapperProps.x}, 0)`}>
                {yTicks.map((item, key) => (
                    <text
                        key={`yTicks_${item.value}_${key}`}
                        fontFamily="Inter, sans-serif"
                        y={item.y + fontSize}
                        textAnchor="end"
                        dominantBaseline="auto"
                        fontSize={fontSize}
                        fontWeight="500"
                        fill={COLORS.MAIN.NEUTRALS[600]}
                        className="apexcharts-text apexcharts-yaxis-label ">
                        <tspan>{item.label}</tspan>
                        <title>{item.label}</title>
                    </text>
                ))}
            </g>
        )
    }

    const renderHorizontalLines = () => {
        return (
            <g transform={`translate(0, 0)`}>
                {Array(yTicks.length - 1)
                    .fill(1)
                    .map((_, key) => (
                        <line
                            x1="0"
                            x2={lineWidth}
                            y1={visualHeight - yTicks[key].y + fontSize}
                            y2={visualHeight - yTicks[key].y + fontSize}
                            stroke={COLORS.MAIN.NEUTRALS[100]}
                            strokeDasharray="0"
                            strokeWidth="1"
                            key={`line_${yTicks[key].y}_${key}`}
                            strokeLinecap="butt"></line>
                    ))}
            </g>
        )
    }

    const renderXticks = () => {
        const getOpactity = () => {
            if (xTickElementWidth * xTicks.length > lineWidth) {
                return 0
            }

            return 1
        }

        return (
            <g transform={`translate(0, ${lineHeight})`} opacity={getOpactity()}>
                <g transform={`translate(0, ${fontSize})`} ref={xWrapper}>
                    {xTicks.map((item, key) => (
                        <text
                            key={`xTicks_${item.x}_${key}`}
                            fontFamily="Inter, sans-serif"
                            x={item.x}
                            y={fontSize}
                            textAnchor="middle"
                            dominantBaseline="auto"
                            fontSize={fontSize}
                            fontWeight="500"
                            fill={COLORS.MAIN.NEUTRALS[600]}>
                            <tspan id="SvgjsTspan1480">{item.name}</tspan>
                            <title>{item.name}</title>
                        </text>
                    ))}
                </g>
                <line
                    x1="0"
                    y1="0"
                    x2={lineWidth}
                    y2="0"
                    stroke={COLORS.MAIN.NEUTRALS[200]}
                    strokeDasharray="0"
                    strokeWidth="1"
                    strokeLinecap="butt"></line>
            </g>
        )
    }

    const renderBlocks = () => {
        const poinstLastIndex = points.length - 1
        const width = poinstLastIndex === 0 ? visualWidth : visualWidth / poinstLastIndex

        const getX = (x: number) => {
            return x - width / 2
        }

        return (
            <>
                <defs>
                    <clipPath id="cut-off-left">
                        <rect x="0" y="0" width={width} height={lineHeight} />
                    </clipPath>
                </defs>
                <g>
                    {points.map((item, key) => (
                        <rect
                            clipPath={key === 0 ? 'url("#cut-off-left")' : undefined}
                            key={`block-hover_${key}`}
                            width={width}
                            height={lineHeight}
                            x={getX(item[0].x)}
                            y={0}
                            opacity={0}
                            onMouseEnter={() => {
                                setLineMark(item)
                                setShowLineMark(true)
                            }}
                            onMouseLeave={() => {
                                setShowLineMark(false)
                            }}
                            onTouchEnd={() => {
                                setLineMark(item)
                                setShowLineMark(true)
                            }}></rect>
                    ))}
                </g>
            </>
        )
    }

    const renderLineMark = () => {
        return (
            <g
                transform={`translate(${lineMark[0]?.x || 0}, 0)`}
                className="pointer-events-none"
                opacity={showLineMark ? 1 : 0}
                style={{
                    transition: 'all 0.1s ease-out'
                }}>
                <line
                    x1="0"
                    y1={fontSize}
                    x2="0"
                    y2={lineHeight}
                    stroke={COLORS.MAIN.NEUTRALS[300]}
                    strokeDasharray="10"
                    strokeLinecap="butt"
                    fill="#b1b9c4"
                    filter="none"
                    fillOpacity="0.9"
                    strokeWidth="1"></line>
            </g>
        )
    }

    const renderPaths = () => {
        const paths = drawLine()
        return (
            <g transform={`translate(0, 0)`} className="pointer-events-none">
                {paths.map((item, key) => (
                    <g key={`path_${key}`}>{renderPath(item, getColor(key))}</g>
                ))}
            </g>
        )
    }

    const renderPath = (path: string, color: string) => {
        const pathRef = useRef<SVGPathElement>(null)
        const pathLength = useMemo(() => (pathRef.current?.getTotalLength() || 0) * 100, [pathRef.current])
        const speed = animationSpeed * (pathLength / 2000)

        return (
            <>
                <path d={`${path} L ${lineWidth}, ${lineHeight} L 0, ${lineHeight} Z`} fill={color} strokeWidth={0} fillOpacity={0}>
                    <animate attributeName="fill-opacity" values="0;0.1" dur={animationSpeed + 's'} fill="freeze" begin="0s"></animate>
                </path>
                <path
                    ref={pathRef}
                    d={path}
                    fill="none"
                    stroke={color}
                    strokeWidth="4"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeDasharray={pathLength > 0 ? pathLength : undefined}
                    strokeDashoffset={pathLength > 0 ? pathLength : undefined}
                    strokeOpacity="1">
                    {pathLength > 0 && (
                        <animate
                            attributeName="stroke-dashoffset"
                            values={`${pathLength};0`}
                            dur={speed + 's'}
                            fill="freeze"
                            begin="0s"></animate>
                    )}
                </path>
            </>
        )
    }

    const renderPoints = () => {
        return (
            <g transform={`translate(0, 0)`} className="pointer-events-none">
                {points.map(item =>
                    item.map((point, key) => (
                        <circle
                            key={`points_${point.x}_${key}`}
                            cx={point.x}
                            cy={point.y}
                            r={lineMark[0]?.x === point.x ? pointSize * 1.3 : pointSize}
                            data-t={`line mark = ${lineMark}, pointX = ${point.x}`}
                            fill={getColor(key)}
                            stroke={COLORS.MAIN.NEUTRALS.light}
                            strokeWidth="2"
                            style={{ cursor: 'pointer', transition: 'all ease-out 0.2s' }}></circle>
                    ))
                )}
            </g>
        )
    }

    return (
        <div className="w-full">
            <div ref={mainWrapper} className="w-full" style={{ minHeight: minHeight }} data-testid="line-chart">
                <svg width={frameWidth} height={frameHeight}>
                    {renderYTicks()}
                    <g className="cursor-crosshair" transform={`translate(${yWrapperProps.width + space}, 0)`}>
                        {renderHorizontalLines()}
                        {renderXticks()}
                        {renderBlocks()}
                        {renderLineMark()}
                        {renderPaths()}
                        {renderPoints()}
                        <foreignObject
                            width={0}
                            height={0}
                            className="pointer-events-none overflow-visible"
                            transform={getTooltipPosition()}
                            opacity={showLineMark ? 1 : 0}
                            style={{
                                transition: 'all 0.1s ease-out'
                            }}>
                            {lineMark.length > 0 && (
                                <div
                                    ref={tooltipRef}
                                    className="relative z-10 overflow-hidden rounded-md bg-white text-sm text-neutrals-500 shadow-md"
                                    style={{
                                        width: tooltipWidth
                                    }}>
                                    <div className="border-b border-neutrals-200 bg-neutrals-100 p-2">{lineMark[0].xTickName}</div>
                                    <div className="p-2">
                                        {lineMark.map((item, key) => (
                                            <Fragment key={`tooltip_item_${key}`}>
                                                {formatLegends(formatYTicks(item.value, item.value), item.label, item.color)}
                                            </Fragment>
                                        ))}
                                    </div>
                                </div>
                            )}
                        </foreignObject>
                    </g>
                </svg>
            </div>
            {points.length > 1 && (
                <div className="flex w-full items-center justify-center">
                    {points[0].map((item, key) => (
                        <Fragment key={`legends_item_${key}`}>
                            <div className="flex items-center p-2 text-sm">
                                <div className="mr-2 h-3.5 w-3.5 rounded-full" style={{ backgroundColor: item.color }}></div>
                                <div>{item.label}</div>
                            </div>
                        </Fragment>
                    ))}
                </div>
            )}
        </div>
    )
}

interface LineChartLoaderProps {
    /**
     * The height ratio of the chart
     * @default 0.33602941176471
     */
    heightRatio?: number
    /**
     * The animation speed of the chart
     * @default 1.8
     */
    animationSpeed?: number
}

export const LineChartLoader = ({ heightRatio = HEIGHT_RATIO, animationSpeed = 1.8 }: LineChartLoaderProps) => {
    return (
        <div className="relative w-full" style={{ padding: `0 0 ${heightRatio * 100}%` }} data-testid="line-chart-loader">
            <svg className="absolute left-0 top-0 h-full w-full" viewBox="0 0 100 100">
                <g>
                    <path
                        stroke={COLORS.MAIN.NEUTRALS[200]}
                        id="baseLine"
                        strokeWidth="1"
                        fill="none"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M0,60 L20,60 L20,60 L40,30 L55,70 L70,30 L80,60 L100,60"></path>

                    <path stroke={COLORS.MAIN.PRIMARY.DEFAULT} strokeWidth="1" fill="none" strokeLinecap="round" strokeLinejoin="round">
                        <animate
                            attributeName="d"
                            values="M0,60 L0,60 L0,60 L0,60 L0,60 L0,60 L0,60 L0,60;M0,60 L20,60 L20,60 L20,60 L20,60 L20,60 L20,60 L20,60; M0,60 L20,60 L20,60 L40,30 L40,30 L40,30 L40,30 L40,30; M0,60 L20,60 L20,60 L40,30 L55,70 L55,70 L55,70 L55,70; M0,60 L20,60 L20,60 L40,30 L55,70 L70,30 L70,30 L70,30; M0,60 L20,60 L20,60 L40,30 L55,70 L70,30 L80,60 L80,60; M0,60 L20,60 L20,60 L40,30 L55,70 L70,30 L80,60 L100,60"
                            dur={animationSpeed + 's'}
                            repeatCount="indefinite"
                        />
                    </path>
                </g>

                <circle r="2" fill={COLORS.MAIN.PRIMARY.DEFAULT} stroke={COLORS.MAIN.NEUTRALS.light} strokeWidth="1">
                    <animateMotion dur={animationSpeed + 's'} repeatCount="indefinite">
                        <mpath href="#baseLine" xlinkHref="#baseLine"></mpath>
                    </animateMotion>
                </circle>
            </svg>
        </div>
    )
}
